import ContentLoader from "react-content-loader";

export const SkeletonArticle = (props: any) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={160}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%", height: "160px" }}
    {...props}
  >
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="71" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="110" rx="3" ry="3" width="80%" height="6" /> 
    <rect x="1" y="2" rx="0" ry="0" width="100%" height="37" /> 
    <rect x="0" y="87" rx="3" ry="3" width="100%" height="6" />
  </ContentLoader>
);

export const SkeletonSubscribers = (props: any) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={124}
    style={{ width: "100%", height: "160px" }}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="71" rx="3" ry="3" width="100%" height="6" /> 
    <rect x="0" y="110" rx="3" ry="3" width="70%" height="6" /> 
    <rect x="1" y="2" rx="0" ry="0" width="100%" height="37" /> 
    <rect x="0" y="87" rx="3" ry="3" width="100%" height="6" />
  </ContentLoader>
);

