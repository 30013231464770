import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@moreirapontocom/npmhelpers";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Subscribe from "../../components/Subscribe/Subscribe.component";
import Author from "../../components/Author/Author.component";
import Footer from "../../components/Footer/Footer.component";

const SubscribeView = () => {
  const params: any = useParams();
  const navigate: any = useNavigate();
  const [hasAuthor, setHasAuthor] = useState(null as any);
  const [authorHandler, setAuthorHandler] = useState(params.authorHandler);

  useEffect(() => {
    if (["articles"].includes(params.authorHandler)) {
      navigate("/login");
      return;
    }

    setAuthorHandler(params.authorHandler);
  }, [params.authorHandler, authorHandler]);

  return <>
    <HelmetProvider>
      <Helmet>
        <title>{authorHandler ? `${authorHandler} - ` : ""} Inscreva-se na newsletter</title>
      </Helmet>
    </HelmetProvider>

    <div className="container Subscribe">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 bg-white shadow-sm p-md-5 pb-5">
          <Author
            authorHandler={authorHandler}
            onAuthor={(e: any) => {
              if (!e || e === null) {
                setHasAuthor(false);
                return;
              }

              setHasAuthor(true);
            }} />

          {hasAuthor && <>
            <Separator size={42} />

            <Subscribe authorHandler={authorHandler} />
          </>}

          <Separator size={42} />

          <div className="text-center">
            <small>
              <a href="https://comunica121.com?utm_source=subscription_page" target="_blank" rel="noopener noreferrer">
                Crie sua newsletter <i className="fas fa-external-link-alt ms-2"></i>
              </a>
            </small>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </>
};

export default SubscribeView;