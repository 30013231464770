import { useState } from "react";
import { Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useFormik } from "formik";
import { subscribeToNewsletter } from "../../services/subscription.service";
import * as yup from "yup";

const Subscribe = (props: any) => {
  const {authorHandler} = props;
  const initialValues: any = {
    name: "",
    email: "",
  };

  const [subscriptionStatus, setSubscriptionStatus] = useState(null as any);

  const formSubscribe: any = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object({
      name: yup.string().required().min(3),
      email: yup.string().email().required(),
    }),
    onSubmit: async (values: any) => {
      setSubscriptionStatus(null);

      const response: any = await subscribeToNewsletter(authorHandler, values).then((r: any) => r).catch((e: any) => e);
      if (response.message === "OK") {
        setSubscriptionStatus("success");
        formSubscribe.resetForm();
        return;
      }

      setSubscriptionStatus("error");
    },
  });

  return <>
    <div className="card shadow-0 border-0 bg-light">
      <div className="card-body">
        <strong>Receba minha Newsletter</strong>

        <Separator size={7} />

        <small className="text-muted">
          Você não vai receber SPAM e pode cancelar a inscrição a qualquer momento.
        </small>

        <Separator size={14} />

        {subscriptionStatus === "success" && <>
          <div className="alert alert-success">
            <i className="fas fa-check-circle me-2"></i> <strong>Inscrição confirmada</strong>
            <Separator size={7} />
            Obrigado por se interessar em receber este conteúdo.<br />
            Em breve você receberá um e-mail de confirmação.<br />
            Caso não receba, verifique sua caixa de spam.
          </div>
        </>}

        {subscriptionStatus === "error" && <>
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-triangle me-2"></i> <strong>Ocorreu um erro ao se inscrever</strong>
            <Separator size={7} />
            Por favor tente novamente mais tarde.
          </div>
        </>}

        {!subscriptionStatus && <>
          <form onSubmit={formSubscribe.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                autoComplete="off"
                name="name"
                value={formSubscribe.values.name}
                onChange={formSubscribe.handleChange}
                disabled={formSubscribe.isSubmitting}
                className="form-control"
                placeholder="Seu nome" />
            </div>

            <Separator size={7} />

            <div className="form-group">
              <input
                type="email"
                autoCapitalize="off"
                autoComplete="off"
                name="email"
                value={formSubscribe.values.email}
                onChange={formSubscribe.handleChange}
                disabled={formSubscribe.isSubmitting}
                className="form-control lowercase"
                placeholder="Seu e-mail" />
            </div>

            <Separator size={14} />

            <button
              type="submit"
              disabled={formSubscribe.isSubmitting || !formSubscribe.isValid}
              className="btn btn-primary w-100">
                <Loading loading={formSubscribe.isSubmitting} parent="inline" color="text-white" />
                {!formSubscribe.isSubmitting && <i className="fas fa-check-circle me-2"></i>} Assinar Newsletter
            </button>
          </form>
        </>}
      </div>
    </div>
  </>
};

export default Subscribe;