import { useContext, useEffect } from "react";
import { Alert, AlertContext, Confirm, Separator } from "@moreirapontocom/npmhelpers";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.component";
import Footer from "../../components/Footer/Footer.component";

const PanelView = () => {
  const {alert} = useContext(AlertContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    const currentUrl: string = window.location.pathname;
    if (["/panel", "/panel/"].includes(currentUrl)) {
      navigate("/panel/articles");
    }
  }, []);

  return <>
    <Alert alert={alert} />
    <Navbar />
    <Confirm />

    <Separator size={21} />

    <div className="container bg-white shadow-sm p-5">

      <Outlet />

    </div>

    <Footer />
  </>
};

export default PanelView;