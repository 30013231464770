import { useState } from "react";
import { Separator } from "@moreirapontocom/npmhelpers";

import "./Review.component.scss";

const ReviewComponent = (props: any) => {
  const {
    title,
    content,
    subscribers,
    replaceTags = true,
  } = props;
  const frontendURL: string = process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000";

  const [currentSubscriberPreview, setCurrentSubscriberPreview] = useState(0);
  const selectCurrentSubscriberPreview = (index: number) => {
    if (index <= 0) {
      setCurrentSubscriberPreview(0);
      return;
    }

    if (index > subscribers.length - 1) {
      setCurrentSubscriberPreview(subscribers.length - 1);
      return;
    }

    setCurrentSubscriberPreview(index);
  };

  const _replaceTagsPreview = () => {
    if (!replaceTags || subscribers.length === 0) {
      return {
        title,
        content,
      }
    }

    let newTitle: string = title;
    let newContent: string = content;

    const firstName: string = subscribers[currentSubscriberPreview].name.split(" ")[0] || subscribers[currentSubscriberPreview].name;

    const regexName: RegExp = /\[\s*(name|nome)\s*\]/g;
    const regexFirstName: RegExp = /\[\s*(first_name|primeiro_nome)\s*\]/g;
    const regexEmail: RegExp = /\[\s*(email)\s*\]/g;
    const regexSlug: RegExp = /\[item slug="([^"]+)" title="([^"]+)"\]/g;

    newTitle = newTitle.replace(regexName, subscribers[currentSubscriberPreview].name);
    newTitle = newTitle.replace(regexFirstName, firstName);
    newTitle = newTitle.replace(regexEmail, subscribers[currentSubscriberPreview].email);

    newContent = newContent.replace(regexName, subscribers[currentSubscriberPreview].name);
    newContent = newContent.replace(regexFirstName, firstName);
    newContent = newContent.replace(regexEmail, subscribers[currentSubscriberPreview].email);

    newContent = newContent.replace(regexSlug, `<a href="${frontendURL}/$1?subscriber=" target="_blank" rel="noopener noreferrer">$2</a>`);

    return {
      title: newTitle,
      content: newContent,
    }
  };

  return <>

      <div className="Review">

        {replaceTags && subscribers.length > 0 && <>
          <div className="row justify-content-center">
            <div className="col-8">

              <div className="text-center">
                <strong>
                  {subscribers.length} destinatários
                </strong>

                <Separator size={7} />

                <div className="row align-items-center text-center">
                  <div className="col-1">
                    <button
                      onClick={() => selectCurrentSubscriberPreview(currentSubscriberPreview - 1)}
                      disabled={currentSubscriberPreview === 0}
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon1">&laquo;</button>
                  </div>
                  <div className="col">
                    <small className="text-muted me-2">#{currentSubscriberPreview+1}</small>
                    <span dangerouslySetInnerHTML={{ __html: `${subscribers[currentSubscriberPreview].name} &laquo;${subscribers[currentSubscriberPreview].email}&raquo;`}}></span>
                  </div>
                  <div className="col-1">
                    <button
                      onClick={() => selectCurrentSubscriberPreview(currentSubscriberPreview + 1)}
                      disabled={currentSubscriberPreview === subscribers.length - 1}
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2">&raquo;</button>
                  </div>
                </div>
              </div>

              <Separator size={21} />

            </div>
          </div>
        </>}

        <div className="card shadow-lg m-5 mt-2 mb-1">
          <div className="card-body">
            <strong>Para: </strong> {replaceTags && subscribers.length > 0 ? subscribers[currentSubscriberPreview].email : "[EMAIL DO DESTINATÁRIO]"}

            <Separator size={7} />

            <strong>Assunto: </strong> {_replaceTagsPreview().title}
          </div>
        </div>

        <div className="card shadow-lg m-5 mt-2 mb-0">
          <div className="card-body">
            <strong>Conteúdo:</strong>

            <hr style={{ borderColor: "#00000050" }} />

            <div dangerouslySetInnerHTML={{ __html: _replaceTagsPreview().content }}></div>
          </div>
        </div>
      </div>
    

    <Separator size={21} />
  </>
};

export default ReviewComponent;