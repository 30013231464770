import { Separator } from "@moreirapontocom/npmhelpers";

const ErrorHandlerProvider = ({ children }: any) => {
  return <>
    <div className="container bg-white p-4 shadow-sm rounded-3 mt-3">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">

          <img src="/logo.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />

          <Separator size={40} />

          <strong>Something went wrong</strong>

          <Separator size={10} />

          <small className="text-muted">Please try again later</small>

          <Separator size={30} />

          <a href="/" className="btn btn-primary">Go to Home</a>

        </div>
      </div>
    </div>
  </>
};

export default ErrorHandlerProvider;