import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Heading,
  Link,
  List,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

import "./Editor.component.scss";

const EditorComponent = (props: any) => {
  const {
    content,
    disabled,
    onChange,
  } = props;

  return <>
    <CKEditor
      disabled={disabled}
      editor={ClassicEditor}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        onChange(data);
      }}
      config={{
        toolbar: {
          items: [
            'heading', '|', 'bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'
          ],
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          Heading,
          Link,
          List,
        ],
        initialData: content,
      }}
    />
  </>
};

export default EditorComponent;