import { useEffect, useState } from "react";
import { Loading, Separator } from "@moreirapontocom/npmhelpers";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { cancelSubscription, getSubscriptionData } from "../../services/subscription.service";
import Footer from "../../components/Footer/Footer.component";
import * as yup from 'yup';

const CancelSubscriptionView = () => {
  const navigate: any = useNavigate();
  const params: any = useParams();
  const searchParams: any = new URLSearchParams(window.location.search);

  const [authorHandler, setAuthorHandler] = useState(params.authorHandler || null);
  const [subscriberId, setSubscriberId] = useState(searchParams.get("subscriber") || null);

  useEffect(() => {
    setAuthorHandler(params.authorHandler);
    setSubscriberId(searchParams.get("subscriber"));
    if (!authorHandler) {
      navigate("/login");
      return;
    }

    if (!subscriberId) {
      navigate(`/${authorHandler}`);
      return;
    }

    _getSubscriptionData(authorHandler, subscriberId);
  }, [params.authorHandler, authorHandler, subscriberId]);

  const [subscriptionData, setSubscriptionData] = useState(null as any);
  const [loadingSubscriptionData, setLoadingSubscriptionData] = useState(true);

  const _getSubscriptionData = async (_authorHandler: string, _subscriberId: string) => {
    const response: any = await getSubscriptionData(_authorHandler, _subscriberId).then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK") {
      setSubscriptionData(response.subscriptionData);
      setLoadingSubscriptionData(false);
      return;
    }

    setLoadingSubscriptionData(false);
  };

  const [unsubscriptionStatus, setUnsubscriptionStatus] = useState(null as any);

  const formCancel: any = useFormik({
    initialValues: {
      reason: "",
    },
    validateOnMount: true,
    validationSchema: yup.object({
      reason: yup.string().required(),
    }),
    onSubmit: async (values: any) => {
      setUnsubscriptionStatus(null);
      const response: any = await cancelSubscription(authorHandler, subscriberId, values.reason).then((r: any) => r).catch((e: any) => e);
      if (response.message === "OK") {
        setUnsubscriptionStatus("success");
        formCancel.resetForm();
        return;
      }

      setUnsubscriptionStatus("error");
    },
  });

  return <>
    <HelmetProvider>
      <Helmet>
        <title>Cancelar Assinatura</title>
      </Helmet>
    </HelmetProvider>

    <div className="container CancelSubscriptionView">

      <div className="row justify-content-center">
        <div className="col-12 col-md-10 bg-white shadow-sm p-5">

          <Loading loading={loadingSubscriptionData} />

          {!loadingSubscriptionData && !subscriptionData && <>
            <Separator size={21} />

            <div className="alert alert-danger">
              <i className="fas fa-exclamation-triangle me-2"></i> <strong>Assinatura não encontrada</strong>
            </div>
          </>}

          {!loadingSubscriptionData && subscriptionData && <>
            <strong>
              <span className="text-muted">
                Cancelar Newsletter de
              </span> "{subscriptionData.author.name} ({subscriptionData.author.handler})"
            </strong>

            <Separator size={21} />

            {unsubscriptionStatus === "success" && <>
              <Separator size={14} />
              <div className="alert alert-success">
                <i className="fas fa-check-circle me-2"></i> <strong>Assinatura cancelada</strong>
                <Separator size={7} />
                Obrigado por ter nos acompanhado até aqui.
              </div>
            </>}

            {unsubscriptionStatus === "error" && <>
              <Separator size={14} />
              <div className="alert alert-danger">
                <i className="fas fa-exclamation-triangle me-2"></i> <strong>Ocorreu um erro ao cancelar a inscrição</strong>
                <Separator size={7} />
                Por favor tente novamente mais tarde.
              </div>
            </>}

            {!unsubscriptionStatus && <>
              <form onSubmit={formCancel.handleSubmit}>

                Ao cancelar a sua inscrição, o email <strong>{subscriptionData.subscriber.email}</strong> não vai mais receber as atualizações deste autor.<br />
                Se você está inscrito em mais de uma newsletter, não se preocupe, você continuará recebendo as atualizações dos outros autores.

                <Separator size={35} />

                Você poderia nos dizer o motivo pelo qual deseja cancelar a sua inscrição na newsletter?

                <Separator size={21} />

                <ul className="list-unstyled">
                  <li className="mb-2">
                    <label>
                      <input
                        type="radio"
                        onChange={formCancel.handleChange}
                        disabled={formCancel.isSubmitting}
                        name="reason"
                        value="not_interesting"
                        className="me-2" /> Não estou mais interessado
                    </label>
                  </li>
                  <li className="mb-2">
                    <label>
                      <input
                        type="radio"
                        onChange={formCancel.handleChange}
                        disabled={formCancel.isSubmitting}
                        name="reason"
                        value="too_many_emails"
                        className="me-2" /> Recebo muitos e-mails
                    </label>
                  </li>
                  <li className="mb-2">
                    <label>
                      <input
                        type="radio"
                        onChange={formCancel.handleChange}
                        disabled={formCancel.isSubmitting}
                        name="reason"
                        value="other"
                        className="me-2" /> Prefiro não informar
                    </label>
                  </li>
                </ul>

                <Separator size={21} />

                <button
                  type="submit"
                  disabled={formCancel.isSubmitting || !formCancel.isValid}
                  className="btn btn-danger me-2">
                    <Loading loading={formCancel.isSubmitting} parent="inline" color="text-white" />
                    {!formCancel.isSubmitting && <i className="fas fa-times-circle me-2"></i>} Cancelar inscrição
                  </button>

                <Link to={`/${authorHandler}`} className="btn btn-outline-secondary">Não cancelar</Link>
              </form>
            </>}
          </>}

        </div>
      </div>

      <Footer />
    </div>
  </>
};

export default CancelSubscriptionView;