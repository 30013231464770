import axios from "axios";

export const getArticle = async (slug: string, subscriberId: string) => await axios.get(`/articles/${slug}/${subscriberId}`).then((res) => res.data).catch((err) => err);

export const getArticleById = async (articleId: string) => await axios.get(`/articles/${articleId}`).then((res) => res.data).catch((err) => err);

export const getArticles = async () => await axios.get("/articles").then((res) => res.data).catch((err) => err);

export const getArticleResults = async (articleId: string) => await axios.get(`/articles/${articleId}/results`).then((res) => res.data).catch((err) => err);

export const createArticle = async () => await axios.post("/articles").then((res) => res.data).catch((err) => err);

export const setWelcomeTemplate = async (articleId: string) => await axios.put(`/articles/${articleId}/setAsWelcomeTemplate`).then((res) => res.data).catch((err) => err);

export const sendArticle = async (payload: {articleId: string, title: string, content: string}) => await axios.post(`/articles/${payload.articleId}/send`, payload).then((res) => res.data).catch((err) => err);

export const updateArticle = async (articleId: string, payload: any) => await axios.put(`/articles/${articleId}`, payload).then((res) => res.data).catch((err) => err);

export const getAiTitleSuggestion = async (articleId: string, payload: any) => await axios.post(`/articles/${articleId}/getAiTitleSuggestion`, payload).then((res) => res.data).catch((err) => err);