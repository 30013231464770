import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig: any = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
};

export const app: any = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const isAuthenticated = (): boolean => {
  return localStorage.getItem("user") !== null && localStorage.getItem("access_token") !== null;
};

export const logout = async (): Promise<any> => {
    await getAuth(app).signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("data");
      localStorage.removeItem("access_token");
      return;
    }).catch((error: any) => {
      console.log("Error on logout", error);
    });
};