import React, { useContext, useEffect, useState } from "react";
import { AlertContext, ConfirmContext, convertFirebaseTimestampToString, copyToClipboard, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { getSubscribers, unsubscribe } from "../../services/subscription.service";
import { SkeletonSubscribers } from "../../components/Skeletons/Skeletons.component";
import { _calculateSubscribers } from "../../services/helpers.service";

const SubscribersView = () => {
  const frontendURLWebsite: string = process.env.REACT_APP_FRONTEND_URL_WEBSITE || "http://localhost:3000";
  const { setAlert } = useContext(AlertContext);
  const { setConfirm } = useContext(ConfirmContext);

  useEffect(() => {
    _getSubscribers();
  }, []);

  const [loadingSubscribers, setLoadingSubscribers] = useState(true);
  const [subscribers, setSubscribers] = useState([]);

  const _getSubscribers = async () => {
    const response: any = await getSubscribers().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setSubscribers(response.subscribers);
      setLoadingSubscribers(false);
      return;
    }

    setLoadingSubscribers(false);
    console.log(response);
  };

  const [loadingUnsubscriber, setLoadingUnsubscriber] = useState(false);
  const [loadingUnsubscriberId, setLoadingUnsubscriberId] = useState("");
  const _unsubscribe = async (subscriber: any) => {
    setLoadingUnsubscriber(true);
    setLoadingUnsubscriberId(subscriber.id);
    console.log("Unsubscribing", subscriber);
    const response: any = await unsubscribe(subscriber.authorHandler, subscriber.id, "manual").then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setAlert({type: "success", message: "Assinante removido com sucesso"});

      // Set subscriber status to opt-out
      const _subscribers: any = [...subscribers];
      const index: number = _subscribers.findIndex((s: any) => s.id === subscriber.id);
      _subscribers[index].status = "opt-out";
      _subscribers[index].email = `**********@${_subscribers[index].email.split("@")[1]}`;
      _subscribers.sort((a: any, b: any) => a.status > b.status ? 1 : -1);
      setSubscribers(_subscribers);

      setLoadingUnsubscriber(false);
      setLoadingUnsubscriberId("");
      return;
    }

    setLoadingUnsubscriber(false);
    setLoadingUnsubscriberId("");
  };

  return <>
    <div className="Subscribers">

      <div className="alert alert-primary">
        <small className="text-muted">
          <i className="fas fa-info-circle me-2"></i> É através deste link que os visitantes se inscrevem em sua lista de emails.<br />
          Compartilhe em suas redes sociais, site, blog, etc.
        </small>

        <Separator size={14} />

        <div className="form-group">
          <label>
            <strong>Este é o seu link de Inscrição</strong>
          </label>

          <Separator size={7} />

          <input
            type="text"
            className="form-control"
            onFocus={(e) => {
              e.target.select();
              copyToClipboard(e.target.value);
              setAlert({type: "success", message: "Link copiado para a área de transferência"});
            }}
            value={`${frontendURLWebsite}/u/moreirapontocom`}
            readOnly />
        </div>
      </div>

      <Separator size={14} />

      <strong>Assinantes</strong>

      <Separator size={21} />

      {loadingSubscribers && <SkeletonSubscribers />}

      {!loadingSubscribers && subscribers.length === 0 && <>
        <strong>Nenhum assinante encontrado</strong>
      </>}

      {!loadingSubscribers && subscribers.length > 0 && <>
        <div className="text-center">
          <small className="text-muted">
            {subscribers.length} assinantes encontrados &bull; {_calculateSubscribers(subscribers).active} ativos ({_calculateSubscribers(subscribers).percentage}%)
          </small>
        </div>

        <Separator size={7} />

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nome</th>
              <th scope="col"></th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col" className="text-end">Data Inscrição</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((subscriber: any, index: number) => {
              return <React.Fragment key={`subscriber-${index}`}>
                <tr className={subscriber.status === "opt-out" ? "text-muted opacity-20" : ""}>
                  <td>{index + 1}</td>
                  <td>{subscriber.name}</td>
                  <td>
                    {subscriber.status === "active" && <>
                      <button
                        type="button"
                        className="btn btn-link btn-sm inline"
                        disabled={loadingUnsubscriber && loadingUnsubscriberId === subscriber.id}
                        onClick={() => {
                          setConfirm({
                            type: "danger", // primary || success || danger || info || warning
                            title: "Tem certeza?",
                            message: `Você está prestes a remover <strong>"${subscriber.name}"</strong> (${subscriber.email}) da sua lista de emails. Esta ação não pode ser desfeita. O assinante não será notificado.`,
                            buttonLabel: "Remover",
                            buttonCancelLabel: "Cancelar",
                            onConfirm: async () => {
                              _unsubscribe(subscriber);
                            },
                          });
                        }}>
                          <Loading loading={loadingUnsubscriber && loadingUnsubscriberId === subscriber.id} parent="inline" />
                          {(!loadingUnsubscriber || (loadingUnsubscriber && loadingUnsubscriberId !== subscriber.id)) && <i className="fas fa-unlink me-2"></i>}
                      </button>
                    </>}
                  </td>
                  <td>{subscriber.email}</td>
                  <td>
                    {subscriber.status === "active" && <span className="badge bg-primary">Inscrito</span>}
                    {subscriber.status === "opt-out" && <span className="badge bg-secondary opacity-50">Saiu da Lista</span>}
                  </td>
                  <td className="text-end text-muted">
                    <small>
                      {convertFirebaseTimestampToString(subscriber.subscribed_at, "DD/MM/YYYY")}
                    </small>
                  </td>
                </tr>
              </React.Fragment>
            })}
          </tbody>
        </table>
      </>}

    </div>
  </>
};

export default SubscribersView;