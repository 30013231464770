import axios from "axios";

export const getSubscribers = async (status: string = "all") => await axios.get(`/subscriptions/${status}`).then((res) => res.data).catch((err) => err);

export const subscribeToNewsletter = async (authorHandler: string, payload: {name: string, email: string}) => await axios.post(`/subscriptions/${authorHandler}`, payload).then((res) => res.data).catch((err) => err);

export const getSubscriptionData = async (authorHandler: string, subscriberId: string) => await axios.get(`/subscriptions/${authorHandler}/${subscriberId}`).then((res) => res.data).catch((err) => err);

export const cancelSubscription = async (authorHandler: string, subscriberId: string, reason: string) => await axios.post(`/subscriptions/${authorHandler}/${subscriberId}`, {reason}).then((res) => res.data).catch((err) => err);

export const unsubscribe = async (authorHandler: string, subscriberId: string, reason: string) => await axios.post(`/subscriptions/${authorHandler}/${subscriberId}`, {reason}).then((res) => res.data).catch((err) => err);
