import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertContext, convertFirebaseTimestampToString, copyToClipboard, Loading, Separator, TextWithTooltip } from "@moreirapontocom/npmhelpers";
import { getArticleResults } from "../../services/articles.service";
import { SkeletonArticle } from "../../components/Skeletons/Skeletons.component";
import ReviewComponent from "../../components/Review/Review.component";
import Footer from "../../components/Footer/Footer.component";

import "./Results.scss";

const ResultsView = (props: any) => {
  const params: any = useParams();
  const { setAlert } = useContext(AlertContext);
  const frontendURL: string = process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000";

  const [articleResults, setArticleResults] = useState(null as any);
  const [recipientsResults, setRecipientsResults] = useState(null as any);
  const [loadingArticleResults, setLoadingArticleResults] = useState(true);
  const [loadingInvisibleArticleResults, setLoadingInvisibleArticleResults] = useState(true);

  const [articleId, ] = useState(params.articleId);

  useEffect(() => {
    _getArticleResults(params.articleId);
  }, [params.articleId]);

  const _getArticleResults = async (_articleId: string, showLoading: boolean = true) => {
    if (showLoading) {
      setLoadingArticleResults(true);
      setLoadingInvisibleArticleResults(true);
    } else {
      setLoadingInvisibleArticleResults(true);
    }
    const response: any = await getArticleResults(_articleId).then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK") {
      setArticleResults(response.article);
      setRecipientsResults(response.recipients);
      setLoadingArticleResults(false);
      setLoadingInvisibleArticleResults(false);
      return
    }

    setLoadingArticleResults(false);
    setLoadingInvisibleArticleResults(false);
    console.log("response", response);
  };

  return <>
    <div className="ResultsView">

      {loadingArticleResults && <SkeletonArticle />}

      {!loadingArticleResults && !articleResults && <>
        <div className="alert alert-warning">
          <strong>Artigo não encontrado</strong>
        </div>
      </>}

      {!loadingArticleResults && articleResults !== null && <>
        <h3><span className="text-muted">Detalhes &raquo; </span> {articleResults.title}</h3>

        <Separator size={7} />

        <small className="text-muted">
          <i className="fas fa-calendar me-2"></i> {convertFirebaseTimestampToString(articleResults.sent_at, "DD/MM/YYYY")}
        </small>

        <Separator size={21} />

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="results-tab" data-bs-toggle="tab" data-bs-target="#results-tab-pane" type="button" role="tab" aria-controls="results-tab-pane" aria-selected="true">Resultados</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="preview-tab" data-bs-toggle="tab" data-bs-target="#preview-tab-pane" type="button" role="tab" aria-controls="preview-tab-pane" aria-selected="false">Preview</button>
          </li>
        </ul>

        <Separator size={21} />

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="results-tab-pane" role="tabpanel" aria-labelledby="results-tab">

            {recipientsResults.length > 0 && <>

              <div className="text-end">
                <button
                  disabled={loadingInvisibleArticleResults}
                  onClick={() => _getArticleResults(articleId, false)}
                  className="btn btn-outline-primary btn-sm">
                    <Loading loading={loadingArticleResults || loadingInvisibleArticleResults} parent="inline" />
                    {!loadingArticleResults && !loadingInvisibleArticleResults && <i className="fas fa-sync me-2"></i>} Atualizar lista
                </button>
              </div>

              <Separator size={21} />

              <table className="table">
                <thead>
                  <tr>
                    <th className="text-muted text-center">
                      <small>#</small>
                    </th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    {/*
                    <th>
                      <TextWithTooltip
                        id="tooltip-article-result-link"
                        tooltip="Cada destinatário recebe um link único para visualizar o artigo. Se quiser reenviar o artigo para alguém, envie o link específico do assinante. Importante: Este não é um link para ser compartilhado em redes sociais!">
                          Link <i className="fas fa-question-circle text-muted opacity-50 ms-2 cursor-pointer" />
                      </TextWithTooltip>
                    </th>
                    */}
                    <th className="text-center">Enviado</th>
                    <th className="text-center">Aberto <i className="text-muted">(beta)</i></th>
                  </tr>
                </thead>
                <tbody>
                  {recipientsResults.map((recipient: any, index: number) => {
                    return <tr key={index}>
                      <td className="text-muted text-center">
                        <small>
                          {index + 1}
                        </small>
                      </td>
                      <td>{recipient.name}</td>
                      <td>{recipient.email}</td>
                      {/*
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            copyToClipboard(`${frontendURL}/moreirapontocom/${articleResults.slug}?subscriber=${recipient.id}`);
                            setAlert({type: "success", message: "Link copiado"});
                          }}
                          className="btn btn-link btn-sm underline-0">
                            <i className="fas fa-link me-2"></i> Copiar link
                        </button>
                      </td>
                      */}
                      <td className="text-center">
                        <i
                          className={`fas fa-check ${recipient.sent_at ? "text-primary" : "text-muted opacity-20"}`}
                          title={recipient.sent_at ? convertFirebaseTimestampToString(recipient.sent_at, "DD/MM/YYYY HH:mm:ss") : "-"}>
                        </i>
                      </td>
                      <td className="text-center">
                        <i
                          className={`fas fa-check ${recipient.opened_at ? "text-primary" : "text-muted opacity-20"}`}
                          title={recipient.opened_at ? convertFirebaseTimestampToString(recipient.opened_at, "DD/MM/YYYY HH:mm:ss") : "-"}>
                        </i>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </>}

          </div>
          <div className="tab-pane fade" id="preview-tab-pane" role="tabpanel" aria-labelledby="preview-tab">

            <ReviewComponent
              articleId={articleId}
              title={articleResults.title}
              content={articleResults.content}
              subscribers={recipientsResults}
              onSend={(e: any) => {
                console.log("onSend", e);
              }}
            />

          </div>
        </div>
      </>}
    </div>
  </>
}

export default ResultsView;